import { Account } from "#agents/models"
import type { AxiosResponse } from "axios"
import { BaseRequest } from "$commons/lib/BaseRequest"

export default {
  Get: class extends BaseRequest<Account> {
    method = "GET"
    endpoint = "/agents/api/account"

    constructor(key: 'global') {
      super()
    }

    processResponse(response: AxiosResponse): Account {
      return this.responseToObject(response, Account)
    }
  }
}
