import { Account } from '#agents/models'
import * as requests from '#agents/requests'
import ErrorUnauthorized from '$commons/lib/ErrorUnauthorized'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { KlassCache } from './KlassCache'
import type { BaseRequest } from '$commons/lib/BaseRequest'
import { useCookies } from '@vueuse/integrations/useCookies'

export const useSessionStore = defineStore('session', () => {
  const cache = new KlassCache<BaseRequest<any>>()
  const account = ref(undefined as Account | null | undefined)
  const error = ref(null)
  const cookies = useCookies(['isSignIn'], { autoUpdateDependencies: true })
  const isSignIn = computed({
    get: () => {
      return !!cookies.get('isSignIn')
    },
    set: (value) => {
      if (value) {
        cookies.set('isSignIn', '1', { path: '/' })
        cookies.set('SignedInByAgent', '1', { path: '/' })
      } else {
        cookies.remove('isSignIn', { path: '/' })
        cookies.remove('SignedInByAgent', { path: '/' })
      }
    }
  })

  async function prepare(ctx: any) {
    if (account.value !== undefined) {
      return
    }

    try {
      account.value = await new requests.account.Get('global').setup(ctx).perform()
    } catch (e) {
      if (e instanceof ErrorUnauthorized) {
        account.value = null
        return
      }
      throw e
    }

    isSignIn.value = true
  }

  function allow() {
    return function(action: string, resource: any) {
      if (account.value == null || account.value == undefined) {
        return false
      }

      return account.value.allow(action, resource)
    }
  }

  function clear() {
    account.value = null
    isSignIn.value = false
    cache.clear()
  }

  return { cache, account, error, prepare, allow, clear, isSignIn }
})
